// Get visible selections

export default (learningobjective_courses, { text, disposition, userId, learningobjectiveid, sortBy, startDate, endDate }) => {
  return learningobjective_courses;
//  return learningobjective_courses.filter((learningobjective_course) => {
//    const textMatch = learningobjective_courses.learningobjectiveid === learningobjectiveid;
//    return textMatch;
//  }).sort((a, b) => {
//      return a.learningobjectiveid < b.learningobjectiveid ? 1 : -1;
//  });
};